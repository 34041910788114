<template>
	<page-container :page="page" class="index">
		<div class="animated-image" />
		<logo />
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();

await fetchPage();
</script>

<style lang="scss" scoped>
.animated-image {
	background: url('~/assets/images/animated-building-med-optimized.gif') no-repeat center center;
	background-size: 180px;
	width: 180px;
	height: 281px;
	margin: 75px auto 50px;
}

@media (max-width: 800px) {
	.animated-image {
		margin: 0 auto 30px;
	}
}
</style>
